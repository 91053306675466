import React from "react"
import Button from "../atoms/Button"

const CTASection = ({
  title,
  titleEC,
  description,
  descriptionEC,
  button,
  buttonEC,
}) => {
  return (
    <>
      {title ? (
        <h3
          className={
            "cta-title font-poppins text-[20px] md:text-[20px] lg:text-[28px] font-[600] mb-4 md:mb-5 lg::mb-10 leading-[1.3em] sm:leading-[1.5em] text-[#202020] " +
              titleEC || ""
          }
        >
          {title}
        </h3>
      ) : (
        ""
      )}
      {description ? (
        <p
          className={
            "cta-description mb-5 sm:mb-1 md:mb-5 lg:mb-10 font-default text-[16px] md:text-[16px] lg:text-[18px] font-poppins text-[#202020] " +
            descriptionEC || ""
          }
        >
          {description}
        </p>
      ) : (
        ""
      )}
      {button && (
          <Button
            to={button?.to || "/"}
            href={button?.href || ""}
            text={button?.text || ""}
            ec={button?.ec + " primary-btn px-6 lg:px-[50px] !py-1 sm:!py-2 lg:!py-3 inline-block" || ""}
            />
        )}
    </>
  )
}

export default CTASection
