import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../components/pages/AboutUs/HeroSection"
import {
  byTheNumbersSection,
  heroSection,
  ourMissionSection,
  ourTeamSection,
  ourValuesSection,
  ourVisionSection,
} from "../Content/AboutUs"
import InnerPageBannerWithTitleDescription from "../components/common/InnerPageBannerWithTitleDescription"
import OurMissionSection from "../components/pages/AboutUs/OurMissionSection"
import OurValuesSection from "../components/pages/AboutUs/OurValuesSection"
import ByTheNumbersSection from "../components/common/ByTheNumbersSection"
import OurTeamSection from "../components/pages/AboutUs/OurTeamSection"

const AboutUs = () => (
  <Layout>
    <Seo
      title="About Us"
      description={`Learn more about Synkli, our mission, values, and the team dedicated to helping you streamline your financial management. Discover how we innovate to support your success.`}
    />

    <HeroSection
      title={heroSection.title}
      description1={heroSection.description1}
      description2={heroSection.description2}
    />

    <InnerPageBannerWithTitleDescription
      title={ourVisionSection.title}
      titleEC={`text-[30px]`}
      description={ourVisionSection.description}
      image={ourVisionSection.image}
      imageWrapperEC={`hidden md:block`}
    />

    <OurMissionSection
      subTitle={ourMissionSection.subTitle}
      title={ourMissionSection.title}
      description={ourMissionSection.description}
      image1={ourMissionSection.image1}
      image2={ourMissionSection.image2}
      image3={ourMissionSection.image3}
      image4={ourMissionSection.image4}
    />

    <OurValuesSection
      subTitle={ourValuesSection.subTitle}
      title={ourValuesSection.title}
      valueSolutions={ourValuesSection.valueSolutions}
    />

    <ByTheNumbersSection
      title={byTheNumbersSection.title}
      numberBoxes={byTheNumbersSection.numberBoxes}
      image={byTheNumbersSection.image}
      imageWrapperEC={`hidden md:block`}
    />

    <OurTeamSection
      subTitle={ourTeamSection.subTitle}
      title={ourTeamSection.title}
      CTATitle={ourTeamSection.CTATitle}
      CTADescription={ourTeamSection.CTADescription}
      CTAImage={ourTeamSection.CTAImage}
    />
  </Layout>
)

export default AboutUs
