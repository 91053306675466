// Hero Section
export const heroSection = {
  title: `Empowering Your <span class="text-[#B695F8]">Financial Future:</span> For Businesses, Individuals, And Accountants`,
  description1: `Synkli is committed to revolutionising the management of financial and accounting processes. We aim to streamline financial procedures for businesses and individuals, ensuring that accuracy and efficiency are within reach for everybody. We streamline and computerise financial tasks using state-of-the-art technology, eradicating the inconvenience and mistakes linked to manual data input.`,
  description2: `At Synkli, we are dedicated to assisting accountants in efficiently managing their varied clientele. Our extensive range of solutions consolidates client management, improves communication, and provides sophisticated reporting and analytics, empowering accountants to provide strategic, data-driven guidance.`
}

// Our Vision Section
export const ourVisionSection = {
    title : `Our <span class="text-[#B695F8]">Vision</span>`,
    description: `Our vision is to be the leading force in transforming financial management through innovation and automation, empowering businesses and individuals to achieve unparalleled accuracy, efficiency, and insight in their financial endeavours. We strive to create a seamless, intuitive platform that simplifies financial tasks and fosters strategic growth for our users worldwide.`,
    image: {
        path: `circled-pattern`,
        alt: `circled-pattern`
    }
}

// Our Mission Section
export const ourMissionSection = {
    subTitle: `OUR MISSION`,
    title: `OUR MISSION`,
    description: `Our mission at Synkli is to streamline financial management for businesses and individuals through a user-friendly and automated platform that guarantees precision, effectiveness, and protection. Our dedication to ongoing innovation enables our users to make well-informed financial choices and confidently reach their objectives.`,
    image1: {
        path: `our-mission-image1`,
        alt: `our-mission-image1`
    },
    image2: {
        path: `our-mission-image2`,
        alt: `our-mission-image2`
    },
    image3: {
        path: `our-mission-image3`,
        alt: `our-mission-image3`
    },
    image4: {
        path: `our-mission-image4`,
        alt: `our-mission-image4`
    },
}

// Our Values Section
export const ourValuesSection = {
    subTitle: `Our Values`,
    title: `Transforming Business Financial Management With Seamless Solutions`,
    valueSolutions: [
        {
            title: `Innovation at Heart`,
            description: `We pursue cutting-edge accounting solutions, ensuring our technology always leads the way in simplifying and enhancing financial management for our users. We believe innovation is critical to staying ahead in the ever-evolving financial landscape.`
        },
        {
            title: `Integrity in Action`,
            description: `We uphold the highest standards of honesty and transparency. Every decision we make, and every action we take is rooted in a commitment to ethical practices, which build trust with our users and within our team.`
        },
        {
            title: `User-Centric Approach`,
            description: `Our users are at the core of everything we do. We design our platform with their needs in mind, delivering intuitive and effective solutions that make managing finances easy for everyone.`
        },
        {
            title: `Striving for Excellence`,
            description: `Synkli is dedicated to excellence in all aspects. We provide continuous improvement and focus on delivering the highest quality to ensure that we consistently meet and exceed our users' expectations.`
        },
        {
            title: `Collaboration`,
            description: `We believe in the power of collaboration, both within our team and with our users. By fostering a culture of teamwork and open communication, we achieve better outcomes and create a supportive community dedicated to shared success.`
        },
    ]
}

// By The Numbers Section
export const byTheNumbersSection = {
    title:`By the <span class="text-[#B695F8]">Numbers</span>`,
    numberBoxes: [
        {
            title: `Accountants Onboard`,
            numberData: `100+`
        },{
            title: `Users Onboarded`,
            titleEC: `md:max-w-[120px]`,
            numberData: `12K+`
        },{
            title: `Total Savings for the Clients`,
            numberData: `12M+`
        },{
            title: `Accountant Hours Saved`,
            numberData: `100K+`
        },
    ],
    image: {
        path: `circled-pattern`,
        alt: `circled-pattern`
    }
}

// HappyCustomer Section
export const ourTeamSection = {
    subTitle: `Our Team`,
    title: `World Class Leadership`,
    CTATitle: `Creating a better relationship and services for clients`,
    CTADescription: `Synkli has completely transformed how we manage our client’s business finances. The intuitive platform and real-time updates have streamlined our processes, saving us time and reducing errors. Their commitment to innovation and user-centric design truly sets them apart. We've seen remarkable improvements in our financial and team management, thanks to Synkli.`,
    CTAImage: {
        path: "world-class-leadership",
        alt: "world-class-leadership"
    }
}