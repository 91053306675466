import React from "react"
import PageSectionHeader from "../../common/PageSectionHeader"
import ImageRenderer from "../../atoms/ImageRenderer"

const OurMissionSection = ({
  subTitle,
  title,
  description,
  image1,
  image2,
  image3,
  image4,
}) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <PageSectionHeader
        subTitle={subTitle}
        title={title}
        description={description}
        descriptionEC={`max-w-[1170px] mx-auto`}
      />
      <div className="flex flex-row flex-wrap gap-5 justify-center mt-20">
        <div className="md:flex-[1] order-[1] w-[46%] md:w-full">
          {image1 && <ImageRenderer img={image1.path} alt={image1.alt} />}
        </div>
        <div className="md:flex-[1] flex flex-row md:flex-col gap-5 justify-center order-[3] md:order-2">
          <div className="w-[46%] md:w-full">
            {image2 && <ImageRenderer img={image2.path} alt={image2.alt} />}
          </div>
          <div className="w-[46%] md:w-full">
            {image3 && <ImageRenderer img={image3.path} alt={image3.alt} />}
          </div>
        </div>
        <div className="md:flex-[1] order-[2] w-[46%] md:w-full md:order-3">
          {image4 && <ImageRenderer img={image4.path} alt={image4.alt} />}
        </div>
      </div>
    </div>
  )
}

export default OurMissionSection
