import React from "react"
import CTASection from "./CTASection"
import ImageRenderer from "../atoms/ImageRenderer"

const CTASectionWithImage = ({
  componentEC,
  title,
  titleEC,
  description,
  descriptionEC,
  buttonn,
  image,
  hoveredImage,
}) => {
  return (
    <>
      <div
        className={
          "flex flex-col md:flex-row gap-4 md:gap-14 justify-between items-center mt-16 " +
            componentEC || ""
        }
      >
        <div className="md:max-w-[300px] lg:max-w-[550px]">
          <CTASection
            title={title}
            titleEC={titleEC}
            description={description}
            descriptionEC={descriptionEC}
            button={buttonn}
          />
        </div>

        {image && hoveredImage ? (
          <div className="solution-card relative cta-image-wrap max-w-[700px] mt-4 sm:mt-0 ">
            <div
              className={`solution-card--normal-image flex-[1] px-3 py-3 transition duration-1000 ${image.ec}`}
            >
              <ImageRenderer img={image.path} alt={image.alt} />
            </div>
            <div
              className={`solution-card--hovered-image flex-[1] px-3 py-3 transition duration-1000 opacity-[0] absolute top-0 left-0  ${image.ec}`}
            >
              <ImageRenderer img={hoveredImage.path} alt={hoveredImage.alt} />
            </div>
          </div>
        ) : (
          <div
            className={`cta-image-wrap max-w-[700px] mt-4 sm:mt-0 ${image.ec}`}
          >
            <ImageRenderer img={image.path} alt={image.alt} />
          </div>
        )}
      </div>
    </>
  )
}

export default CTASectionWithImage
