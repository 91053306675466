import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"

const ByTheNumbersSection = ({
  contentAreaEC,
  title,
  titleEC,
  numberBoxes,
  image,
  imageWrapperEC,
}) => {
  return (
    <div className="synkli-section--horizontal-space">
      <div className="mt-20 md:mt-24 mb-10 bg-[#0A1E46] rounded-[25px]">
        <div className="flex flex-col md:flex-row gap-5 items-center">
          <div
            className={`flex-[3] lg:flex-[2] flex flex-col justify-between gap-10 md:gap-14 px-6 md:pl-10 lg:pl-16 py-10 md:py-16 ${contentAreaEC}`}
          >
            <h2
              className={`text-white text-[30px] md:text-[34px] lg:text-[48px] leading-[1.2em] font-[700] ${titleEC}`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className="flex flex-col md:flex-row gap-5">
              {numberBoxes &&
                numberBoxes.map((numberBox, index) => (
                  <div key={`numberBox_` + index} className="flex-[1]">
                    <h4
                      className={`text-white text-[18px] leading-[1.4em] font-[200] mb-2 md:mb-4 ${numberBox.titleEC}`}
                      dangerouslySetInnerHTML={{ __html: numberBox.title }}
                    />
                    <h3
                      className="text-white font-[600] text-[44px] leading-[1.4em]"
                      dangerouslySetInnerHTML={{
                        __html: numberBox.numberData,
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
          {image && (
            <div className={`flex-[1] ${imageWrapperEC}`}>
              <div className="max-w-[200px] ml-auto">
                <ImageRenderer img={image.path} alt={image.alt} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ByTheNumbersSection
