import React from "react"
import PageSectionHeader from "../../common/PageSectionHeader"
import CTASectionWithImage from "../../molecules/CTASectionWithImage"

const OurTeamSection = ({ subTitle, title, CTATitle, CTADescription, CTAImage }) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <PageSectionHeader subTitle={subTitle} title={title} />
      <CTASectionWithImage
      componentEC={`flex-col md:flex-row-reverse`}
        title={CTATitle}
        description={CTADescription}
        image={{
          path: CTAImage.path,
          alt: CTAImage.alt,
        }}
      />
    </div>
  )
}

export default OurTeamSection
