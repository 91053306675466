import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"

const PageSectionHeader = ({
  subTitle,
  subTitleEC,
  title,
  titleEC,
  titleIcon,
  description,
  descriptionEC,
}) => {
  return (
    <>
      {subTitle ? (
        <h5
          className={
            "text-center font-default text-[12px] sm:text-[16px] font-poppins text-[#B695F8] border-2 border-[#B695F8] rounded-3xl py-[1px] px-3 max-w-max mx-auto min-w-[120px] " +
              subTitleEC || ""
          }
        >
          {subTitle}
        </h5>
      ) : (
        ""
      )}

      {title ? (
        <h2
          className={
            "text-center font-[600] text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-[#2A2A2A] mt-5 max-w-[900px] mx-auto !leading-[1.3em] " +
              titleEC || ""
          }
        >
          {title}{" "}
          {titleIcon ? (
            <span className="relative top-[8px] hidden lg:inline-block">
              <ImageRenderer img={"sm-star-icon-for-title"} />
            </span>
          ) : (
            ""
          )}
        </h2>
      ) : (
        ""
      )}

      {description ? (
        <p
          className={
            "mt-3 sm:mt-5 text-center font-default text-lg font-poppins text-[#202020] " +
              descriptionEC || ""
          }
        >
          {description}
        </p>
      ) : (
        ""
      )}
    </>
  )
}

export default PageSectionHeader
