import React from "react"
import PageSectionHeader from "../../common/PageSectionHeader"

const OurValuesSection = ({ subTitle, title, description, valueSolutions }) => {
  return (
    <div className="synkli-section--horizontal-space synkli-section--vertical-space">
      <PageSectionHeader
        subTitle={subTitle}
        title={title}
        titleEC={`!max-w-[1200px]`}
        description={description}
      />

      <div className="mt-10 md:mt-16">
        {valueSolutions.map((solution, index) => (
            <div key={`valueSolution_` + index} className="flex flex-col md:flex-row gap-4 md:gap-10 px-4 py-8 border-b border-[#DCDCDC]">
                <h5 className="flex-[1] text-[#202020] text-[22px] leading-[1.4em] font-[500]" dangerouslySetInnerHTML={{__html : solution.title}} />
                <p className="flex-[2] text-[16px] leading-[1.4em] text-[#2A2A2A]" dangerouslySetInnerHTML={{__html: solution.description}} />
            </div>
        ))}
      </div>
    </div>
  )
}

export default OurValuesSection
